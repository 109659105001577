import React, { useEffect } from 'react';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Flex } from '@abyss/web/ui/Flex';
import { Button } from '@abyss/web/ui/Button';
import { Link } from '@abyss/web/ui/Link';
import { useGetnochangetransQuery } from '@src/store/services/ParentServices';
import { useGetChildNoChangeQuery } from '@src/store/services/ChildServices';
import { getDetailLink } from '@src/utils';
import { LoadingScreen } from '@src/common/LoadingScreen';

export const NoChangeTransaction = ({ fileId, setId, isChild, fileDetail }) => {
  const { data: noChangeTranList, isLoading } = isChild
    ? useGetChildNoChangeQuery(setId)
    : useGetnochangetransQuery(fileId);

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Severity',
        accessor: 'severityCode',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Customer Name',
        accessor: 'customerName',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
      },
      {
        Header: 'Policy Number',
        accessor: 'polNum',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Subscriber ID',
        accessor: 'subscrId',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Name',
        accessor: 'name',

        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Rel',
        accessor: 'relationship',

        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'DOB',
        accessor: 'dob',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Type',
        accessor: 'type',

        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Status',
        accessor: 'stsCode',

        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Error Name',
        accessor: 'warningName',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Detail View',
        accessor: 'detailView',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        label: 'Customer Name',
        width: 80,
        minWidth: 80,
        Cell: ({ row }) => {
          const { fileRecieveDate, customerName, custSubGroup } = fileDetail;
          const { transSetId, subscrId, transId, name } = row?.original || {};

          const sanitizedName = name?.replace(/,/g, ' ');
          const sanitizedFileRecieveDate = fileRecieveDate.replace(/\//g, '-');
          const nameOrGroup =
            (customerName?.trim().length > 0 ? customerName : custSubGroup) ??
            custSubGroup;

          const detailViewLink = `/home/detailView/${transSetId}/${subscrId}/${transId}/${sanitizedName}/${sanitizedFileRecieveDate}/${nameOrGroup}`;

          // const detailViewLinkNew = `/home/detailView/${row?.original?.transSetId}/${row?.original?.subscrId}/${row?.original?.transId}`;

          return (
            <Flex
              css={{
                gap: '10px',
              }}
            >
              <Link size="10px" target="_blank" href={detailViewLink}>
                <Button
                  variant="outline"
                  size="$sm"
                  css={{
                    fontSize: '12px',
                  }}
                >
                  <strong>Detail View</strong>
                </Button>
              </Link>
            </Flex>
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    if (noChangeTranList) {
      dataTableProps.setData(noChangeTranList);
    }
  }, [noChangeTranList]);

  const dataTableProps = useDataTable({
    initialData: [],
    initialColumns: columns,
    uniqueStorageId: 'data-table-usage',
    showPagination: true,
    pageSizeOptions: [10, 25, 50, 100, 500],
    showGlobalFilter: true,
    showTableSettings: false,
    showFullscreenButton: true,
    highlightRowOnHover: true,
    showDownloadButton: true,
    showFilterDataset: true,
  });

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <DataTable hideTitleHeader tableState={dataTableProps} />
  );
};
