import React, { useState, useEffect } from 'react';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Flex } from '@abyss/web/ui/Flex';
import { Button } from '@abyss/web/ui/Button';
import { Link } from '@abyss/web/ui/Link';
import { useGetAllTransQuery } from '@src/store/services/ParentServices';
import { useGetChildAllTransQuery } from '@src/store/services/ChildServices';
import { getCachedUserDetails, getDetailLink } from '@src/utils';
import { LoadingScreen } from '@src/common/LoadingScreen';
import { getEEMSURL } from '@src/env.config';

const reportConfirmText = {
  C: 'Are you sure you want to reject these records?',
  U: 'Are you sure you want to continue processing these records?',
  O: 'Are you sure you want to submit these records for reprocessing with override?',
  N: 'Are you sure you want to submit these records for reprocessing without override?',
  E: 'Are you sure you want to continue processing these records?',
};

export const AllTransactions = ({
  fileId,
  setId,
  isChild,
  procStatus,
  fileDetail,
}) => {
  console.log('fileDetail in all transactionss', fileDetail);
  const [isInternal, setIsInternal] = useState(false);

  useEffect(() => {
    const { userType } = getCachedUserDetails();
    setIsInternal(userType === 'I');
  }, []);

  const { data: allChangeTranList, isLoading } = isChild
    ? useGetChildAllTransQuery(setId)
    : useGetAllTransQuery(fileId);
  const router = useRouter();
  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Severity',
        accessor: 'severityCode',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 80,
        width: 80,
      },
      {
        Header: 'Customer Name',
        accessor: 'customerName',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
      },
      {
        Header: 'Policy Number',
        accessor: 'polNum',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 100,
        width: 100,
      },
      {
        Header: 'Subscriber ID',
        accessor: 'subscrId',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 120,
        width: 120,
      },
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 120,
        width: 120,
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Rel',
        accessor: 'relationship',
        minWidth: 75,
        width: 75,
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'DOB',
        accessor: 'dob',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 95,
        width: 95,
      },
      {
        Header: 'Type',
        accessor: 'type',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 100,
        width: 100,
      },
      {
        Header: 'Status',
        accessor: 'stsCode',
        minWidth: 100,
        width: 100,
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Warning Name',
        accessor: 'warningName',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 100,
        width: 100,
      },
      {
        Header: 'Customer Data',
        accessor: 'custData',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 100,
        width: 100,
      },
      {
        Header: 'Source Data',
        accessor: 'sourceData',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 80,
        width: 80,
      },
      {
        Header: 'Detail View',
        accessor: 'detailView',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        minWidth: 150,
        width: 150,
        Cell: ({ row }) => {
          const { fileRecieveDate, customerName, custSubGroup } = fileDetail;
          const { transSetId, subscrId, transId, name } = row?.original || {};

          const sanitizedName = name?.replace(/,/g, ' ');
          const sanitizedFileRecieveDate = fileRecieveDate.replace(/\//g, '-');
          const nameOrGroup =
            (customerName?.trim().length > 0 ? customerName : custSubGroup) ??
            custSubGroup;

          const detailViewLink = `/home/detailView/${transSetId}/${subscrId}/${transId}/${sanitizedName}/${sanitizedFileRecieveDate}/${nameOrGroup}`;

          const detailViewLinkNew = `/home/detailView/${row?.original?.transSetId}/${row?.original?.subscrId}/${row?.original?.transId}`;

          return (
            <Flex
              css={{
                gap: '10px',
              }}
            >
              <Link size="10px" target="_blank" href={detailViewLink}>
                <Button
                  variant="outline"
                  size="$sm"
                  css={{
                    fontSize: '12px',
                  }}
                >
                  <strong>Detail View</strong>
                </Button>
              </Link>
            </Flex>
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    if (allChangeTranList) {
      dataTableProps.setData(allChangeTranList);
    }
    console.log('Processing Stats', procStatus);
  }, [allChangeTranList]);

  const dataTableProps = useDataTable({
    initialData: [],
    initialColumns: columns,

    uniqueStorageId: 'data-table-usage',
    showPagination: true,
    pageSizeOptions: [10, 25, 50, 100, 500],
    showGlobalFilter: true,
    showTableSettings: false,
    showFullscreenButton: true,
    highlightRowOnHover: true,
    showDownloadButton: true,
    showFilterDataset: true,
  });

  const handleReprocessAction = (reportType) => {
    if (window.confirm(reportConfirmText[reportType])) {
      const windowFeatures =
        'width=300,height=200,menubar=no,toolbar=no,location=no,status=no';
      const newWindow = window.open(
        `${getEEMSURL()}rpt/reprocess.do?reprAct=${reportType}&tranSetId=${setId}`,
        '',
        windowFeatures
      );
      if (newWindow) {
        setTimeout(() => {
          newWindow.close();
        }, 2000);
      }
      router.navigate('/');
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <React.Fragment>
      <Flex
        css={{
          justifyContent: 'flex-end',
          gap: '10px',
        }}
      >
        {procStatus?.includes('COMPLETED_WITH_ERRORS') &&
          isChild == true &&
          isInternal == true && (
            <Button
              variant="outline"
              onClick={() => {
                return handleReprocessAction('E');
              }}
            >
              <strong>Reprocess All Errors</strong>
            </Button>
          )}
        {procStatus?.includes('THRESHOLD_EXCEEDED') &&
          isChild == true &&
          isInternal == true && (
            <React.Fragment>
              <Button
                variant="outline"
                onClick={() => {
                  return handleReprocessAction('C');
                }}
              >
                <strong>Reject</strong>
              </Button>
              <Button
                variant="outline"
                onClick={() => {
                  return handleReprocessAction('U');
                }}
              >
                <strong>Continue Processing All</strong>
              </Button>
              <Button
                variant="outline"
                onClick={() => {
                  return handleReprocessAction('O');
                }}
              >
                <strong>Reprocess With Override</strong>
              </Button>
              <Button
                variant="outline"
                onClick={() => {
                  return handleReprocessAction('N');
                }}
              >
                <strong>Reprocess No Override</strong>
              </Button>
            </React.Fragment>
          )}
      </Flex>

      <DataTable hideTitleHeader tableState={dataTableProps} />
    </React.Fragment>
  );
};
